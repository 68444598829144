<template>
  <span>
    <i v-if="icon" class="text-gray-400 w-5" :class="icon"></i>
    <strong v-if="label" class="pr-1">{{ label }}:</strong>
    <span>
      <slot>{{ value }}</slot>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    label: String,
    value: String,
    icon: String,
  },
}
</script>

<style></style>
